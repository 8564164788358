import { computed, useContext, unref } from "@nuxtjs/composition-api";
const CAPITAL_CITY_ID = "2";

export default function useCapital() {
  const { store, $logger } = useContext();
  const isCapital = (value) => {
    return unref(value)?.id === CAPITAL_CITY_ID;
  };

  const capitalCity = computed(() => {
    const cities = store.getters["data/cities"];
    if (!cities) {
      $logger.debug("Cities are not loaded yet");
      return null;
    }
    const capitalCity = store.getters["data/cities"]?.find((city) => {
      return city.id === CAPITAL_CITY_ID;
    });
    if (!capitalCity) {
      $logger.fatal("Capital city cannot be found among cities");
      return null;
    }
    return capitalCity;
  });

  const capitalCounty = computed(() => {
    const counties = store.getters["data/counties"];
    if (!counties) {
      $logger.debug("Counties are not loaded yet");
      return null;
    }
    const capitalCounty = store.getters["data/counties"]?.find((county) => {
      return county.id === capitalCity.value.countyId;
    });
    return capitalCounty;
  });

  const capitalSeoName = computed(() => {
    const cities = store.getters["data/cities"];
    if (!cities) {
      $logger.debug("Cities are not loaded yet");
      return null;
    }
    if (!capitalCity.value) {
      return null;
    }
    return capitalCity.value?.seoName;
  });

  return { isCapital, capitalSeoName, capitalCounty };
}
