<template>
  <CollapsibleLinks>
    <template #trigger="{ toggle }">
      <a
        class="font-bold text-primary hover:text-primary-dark text-lg"
        @click.stop.prevent="toggle"
        >{{ $t("footer.forMates") }}</a
      >
    </template>
    <div class="flex flex-col mb-3 gap-1 text-sm">
      <a href="regisztracio" class="text-white">
        {{ $t("footer.register") }}
      </a>
      <NuxtLink class="text-white" :to="localePath({ name: 'buyTendersPage' })">
        {{ $t("footer.jobs") }}
      </NuxtLink>
    </div>
  </CollapsibleLinks>
</template>

<script>
import CollapsibleLinks from "./CollapsibleLinks.vue";

export default {
  components: { CollapsibleLinks },
};
</script>
