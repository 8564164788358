<template>
  <div
    class="cookie-banner fixed bottom-0 p-4 h-auto grid grid-cols-1 md:grid-cols-[1fr_200px] gap-4 bg-white z-[10000] border-t border-gray-300 items-center"
    :class="{ hidden: hideCookieBanner }"
  >
    <div>
      <p class="font-bold pb-4">Ez a weboldal sütiket használ</p>
      <p class="max-md:text-sm">
        Sütiket használunk a tartalmak és hirdetések személyre szabásához,
        közösségi funkciók biztosításához, valamint weboldalforgalmunk
        elemzéséhez. Ezenkívül közösségi média-, hirdető- és elemező
        partnereinkkel megosztjuk az weboldalhasználatra vonatkozó adataidat,
        akik kombinálhatják az adatokat más olyan adatokkal, amelyeket megadtál
        számukra vagy az általad használt más szolgáltatásokból gyűjtöttek.
      </p>
    </div>
    <div class="flex flex-row md:flex-col gap-4">
      <JoszakiButton type="info" expanded @click="acceptCookie">
        Elfogadom
      </JoszakiButton>
      <JoszakiButton
        type="info"
        outline
        inverted
        expanded
        @click="declineCookie"
      >
        Elutasítom
      </JoszakiButton>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const cookie = this.$cookie.get("cookieBanner");

    return {
      hideCookieBanner: cookie === "accepted" || cookie === "declined",
    };
  },
  mounted() {
    if (!this.hideCookieBanner) {
      this.$trackers.trackEvent({
        title: "Cookie Banner shown",
      });
    }
  },
  methods: {
    acceptCookie() {
      this.$trackers.trackClick("CookieBannerAccept");
      this.$cookie.set("cookieBanner", "accepted", {
        maxAge: 60 * 60 * 24 * 7 * 8,
        path: "/",
      });
      this.hideCookieBanner = true;
    },
    declineCookie() {
      this.$trackers.trackClick("CookieBannerDecline");
      this.$cookie.set("cookieBanner", "declined", {
        maxAge: 60 * 60 * 24 * 7 * 8,
        path: "/",
      });
      this.hideCookieBanner = true;
    },
  },
};
</script>
