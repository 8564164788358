<template>
  <CollapsibleLinks>
    <template #trigger="{ toggle }">
      <a
        class="font-bold text-primary hover:text-primary-dark text-lg"
        @click.stop.prevent="toggle"
        >{{ $t("footer.content") }}</a
      >
    </template>
    <div class="flex flex-col mb-3 gap-1 text-sm">
      <NuxtLink
        :to="
          localePath({
            name: 'requestForTender',
          })
        "
        target="_blank"
        class="text-white"
        @click.prevent.stop="requestTender({ source: 'footer' })"
      >
        {{ $t("footer.tender") }}
      </NuxtLink>
      <NuxtLink
        v-if="!isSuperprofikCz"
        :to="localePath({ name: 'pricesPage' })"
        class="text-white"
      >
        {{ $t("footer.pricing") }}
      </NuxtLink>
      <NuxtLink
        v-if="!isSuperprofikCz"
        :to="localePath({ name: 'professionsLandingPage' })"
        class="text-white"
      >
        {{ $t("footer.professions") }}
      </NuxtLink>
      <a v-if="!isSuperprofikCz" href="/telepules" class="text-white">{{
        $t("footer.cities")
      }}</a>

      <NuxtLink
        v-if="!isSuperprofikCz"
        class="text-white"
        :to="localePath({ name: `new-professionals` })"
      >
        {{ $t("footer.newProfessionals") }}
      </NuxtLink>
    </div>
  </CollapsibleLinks>
</template>

<script>
import CollapsibleLinks from "./CollapsibleLinks.vue";
import { useRequestTender } from "~/composables/requestTender";
import { useBrand } from "~/composables/useBrand";

export default {
  components: { CollapsibleLinks },
  setup() {
    const { requestTender } = useRequestTender();
    const { isSuperprofikCz } = useBrand();
    return {
      requestTender,
      isSuperprofikCz,
    };
  },
};
</script>
