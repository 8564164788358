var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col mb-4"},[(_vm.profession)?_c('NuxtLink',{staticClass:"text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mr-auto",attrs:{"to":_vm.localePath({
        name: 'professionals',
        params: {
          profession: _vm.profession.seoName,
          city: _vm.county.seoName,
        },
      })}},[_vm._v(" "+_vm._s(_vm.county.name)+" ")]):_c('NuxtLink',{staticClass:"text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mr-auto",attrs:{"to":_vm.localePath({ name: 'county', params: { county: _vm.county.seoName } })}},[_vm._v(" "+_vm._s(_vm.county.name)+" ")]),(_vm.profession)?_c('NuxtLink',{staticClass:"text-xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto",attrs:{"to":_vm.localePath({
        name: 'professionals',
        params: {
          profession: _vm.profession.seoName,
          city: _vm.county.largestCity.seoName,
        },
      })}},[_vm._v(" "+_vm._s(_vm.county.largestCity.name)+" ")]):_c('NuxtLink',{staticClass:"text-xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto",attrs:{"to":_vm.localePath({
        name: 'countyCity',
        params: { county: _vm.county.seoName, city: _vm.county.largestCity.seoName },
      })}},[_vm._v(" "+_vm._s(_vm.county.largestCity.name)+" ")]),(_vm.profession)?_c('NuxtLink',{staticClass:"text-1xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto",attrs:{"to":_vm.localePath({
        name: 'professionals',
        params: {
          profession: _vm.profession.seoName,
          city: _vm.county.seoName,
        },
      })}},[_vm._v(" "+_vm._s(_vm.$t("personList.allInCounty"))+" ")]):_c('NuxtLink',{staticClass:"text-1xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto",attrs:{"to":_vm.localePath({ name: 'county', params: { county: _vm.county.seoName } })}},[_vm._v(" "+_vm._s(_vm.$t("personList.allInCounty"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }