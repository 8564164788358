<template>
  <div class="flex flex-col mb-4">
    <NuxtLink
      v-if="profession"
      class="text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mr-auto"
      :to="
        localePath({
          name: 'professionals',
          params: {
            profession: profession.seoName,
            city: county.seoName,
          },
        })
      "
    >
      {{ county.name }}
    </NuxtLink>
    <NuxtLink
      v-else
      class="text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mr-auto"
      :to="localePath({ name: 'county', params: { county: county.seoName } })"
    >
      {{ county.name }}
    </NuxtLink>

    <NuxtLink
      v-if="profession"
      class="text-xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto"
      :to="
        localePath({
          name: 'professionals',
          params: {
            profession: profession.seoName,
            city: county.largestCity.seoName,
          },
        })
      "
    >
      {{ county.largestCity.name }}
    </NuxtLink>
    <NuxtLink
      v-else
      class="text-xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto"
      :to="
        localePath({
          name: 'countyCity',
          params: { county: county.seoName, city: county.largestCity.seoName },
        })
      "
    >
      {{ county.largestCity.name }}
    </NuxtLink>

    <NuxtLink
      v-if="profession"
      class="text-1xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto"
      :to="
        localePath({
          name: 'professionals',
          params: {
            profession: profession.seoName,
            city: county.seoName,
          },
        })
      "
    >
      {{ $t("personList.allInCounty") }}
    </NuxtLink>
    <NuxtLink
      v-else
      class="text-1xl font-bold text-white hover:text-white ml-4 pt-2 mr-auto"
      :to="localePath({ name: 'county', params: { county: county.seoName } })"
    >
      {{ $t("personList.allInCounty") }}
    </NuxtLink>
  </div>
</template>

<script>
export default {
  props: {
    county: {
      type: Object,
      required: true,
    },
    profession: {
      type: Object,
      default: null,
    },
  },
};
</script>
