<template>
  <CollapsibleLinks>
    <template #trigger="{ toggle }">
      <a
        class="font-bold text-primary hover:text-primary-dark text-lg"
        @click.stop.prevent="toggle"
        >{{ $t("footer.useful") }}</a
      >
    </template>
    <div class="flex flex-col mb-3 gap-1 text-sm">
      <NuxtLink :to="localePath({ name: 'terms' })" class="text-white">{{
        $t("footer.tos")
      }}</NuxtLink>
      <a v-if="!isSuperprofikCz" href="/static/gyik" class="text-white">{{
        $t("footer.faq")
      }}</a>
      <NuxtLink
        v-if="!isSuperprofikCz"
        :to="localePath({ name: 'aboutUs' }) + '#impressum'"
        class="text-white"
      >
        {{ $t("footer.impressum") }}
      </NuxtLink>
      <a :href="contactFormUrl" class="text-white" target="_blank">
        {{ $t("footer.contacts") }}
      </a>
    </div>
  </CollapsibleLinks>
</template>

<script>
import CollapsibleLinks from "./CollapsibleLinks.vue";
import { useBrand } from "~/composables/useBrand";

export default {
  components: { CollapsibleLinks },
  setup() {
    const { isSuperprofikCz, isJoszaki } = useBrand();
    const contactFormUrl = process.env.CONTACT_FORM;
    return { isSuperprofikCz, isJoszaki, contactFormUrl };
  },
};
</script>
