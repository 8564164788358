<template>
  <div id="main-body" :class="{ [$route.query?.referrer ?? '']: true }">
    <PublicSiteNavBar v-if="!noBrandActive" />
    <ModalTender v-if="isNewTender && newTender.state.modalOpen" />
    <JoszakiToastContainer />
    <JoszakiAlert />
    <JoszakiConfirm />
    <DefaultHeaderSetter />
    <!-- NOTE: .container requires a left/right padding in order to avoid overflow-x on mobile -->
    <!-- NOTE: .py-5 equals with the .block bottom margin (we might refactor this globally in the future) -->
    <Nuxt />
    <ProfessionLinks v-if="showProfessionList && !noBrandActive" />
    <CityList v-if="showCityList" />
    <LogoSlogan v-if="showSlogan && !noBrandActive" />
    <TheFooter v-if="!noBrandActive" />
    <!-- 
        TODO: fix this because it's throwing appendChild error in the production build, probably because isNewTenderException
        <PanelFloatTender
        v-if="
          isNewTender &&
          newTender.state.showFloater &&
          !newTender.isOpen.value &&
          !isNewTenderException
        "
      /> -->

    <CookieBanner v-if="$ab.isVariant('cookie-banner', 'test')" />
  </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
import { mapGetters } from "vuex";
import { mapState } from "pinia";
import { useAdminStore } from "~/stores/admin";
import ProfessionLinks from "~/components/people/ProfessionLinks.vue";
import DefaultHeaderSetter from "~/components/_refactored/DefaultHeaderSetter.vue";
import CityList from "~/components/people/CityList.vue";
import LogoSlogan from "~/components/_refactored/footer/LogoSlogan.vue";
import ModalTender from "~/components/newTender/ModalTender.vue";
import JoszakiAlert from "~/components/joszaki/JoszakiAlert.vue";
import { useUserStore } from "~/stores/user";
import CookieBanner from "~/components/_refactored/cookie-banner/CookieBanner.vue";
import JoszakiToastContainer from "~/components/_refactored/common/JoszakiToastContainer.vue";
import PublicSiteNavBar from "~/components/_refactored/header/PublicSiteNavBar.vue";
import JoszakiConfirm from "~/components/joszaki/JoszakiConfirm.vue";
import { useNewTender } from "~/composables/tender/useNewTender";
import TheFooter from "~/components/_refactored/TheFooter.vue";
import { useNoBrandActive } from "~/composables/useNoBrandActive";

export default defineComponent({
  components: {
    CookieBanner,
    PublicSiteNavBar,
    ProfessionLinks,
    CityList,
    LogoSlogan,
    ModalTender,
    JoszakiAlert,
    JoszakiToastContainer,
    JoszakiConfirm,
    TheFooter,
    DefaultHeaderSetter,
  },
  setup() {
    const newTender = useNewTender();

    return {
      adminStore: useAdminStore(),
      newTender,
      noBrandActive: useNoBrandActive(),
    };
  },
  computed: {
    isNewTenderException() {
      const exceptions = ["prices"];
      const routeName = (this.$route.name ?? "").split("_")[0];
      return exceptions.includes(routeName);
    },
    ...mapState(useUserStore, {
      me: "me",
    }),
    selectedCity() {
      return this.$store.getters["search/city"];
    },
    ...mapGetters("listPage", {
      city: "city",
      profession: "profession",
      county: "county",
    }),
    ...mapGetters("layout", ["showSlogan", "showProfessions", "showCities"]),
    showCityList() {
      return !!this.city || this.showCities;
    },
    showProfessionList() {
      return (
        (!!this.profession && (!!this.city || !!this.county)) ||
        this.showProfessions
      );
    },
    isNewTender() {
      return (
        this.$ab.isVariant("new-tender-2", "variant1") ||
        this.$ab.isVariant("new-tender-2", "variant2")
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.$store.dispatch("layout/reset");
      }
    },
  },
});
</script>
